import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import { HTMLAttributes, ReactNode } from "react";

import { MainCover } from "./MainCover";
import { MainLayoutNav } from "./main-layout/MainLayoutNav";
import { NAV_ITEM_SIZE_CSS_VAR } from "./main-layout/MainLayoutNavItem";
import { AuthService } from "../auth/AuthService";

/*
set (in `Main`)
> flex-direction: column;
 set (in `Nav`)
> flex-direction: row;
> overflow-y: hidden;

to set the navbar on top
*/

const Main = styled.div`
	${NAV_ITEM_SIZE_CSS_VAR}: 64px;

	display: flex;
	flex: 1;
	flex-direction: row;
	max-height: 100%;
	overflow: auto;
`;
const Nav = styled(MainLayoutNav)`
	flex: 0 0 var(${NAV_ITEM_SIZE_CSS_VAR});
	flex-direction: column;
	left: 0;
	max-height: 100%;
	max-width: 100%;
	overflow-x: hidden;
	position: sticky;
	top: 0;
`;
const Content = styled.div`
	display: flex;
	flex: 1;
	overflow-x: hidden;
	position: relative;

	> * {
		max-width: 100%;
	}
`;

/** Props for {@link MainLayout} */
export interface MainLayoutProps extends HTMLAttributes<HTMLDivElement> {
	/** Main content of the layout */
	children: ReactNode;
}

const time = 350;
const Cover = styled(MainCover)`
	height: 100vh;
	position: absolute;
	width: 100vw;
`;

/** Main layout of the application (when user is authenticated) */
export function MainLayout(props: MainLayoutProps) {
	const { t } = useTranslation();

	const { data, isLoading } = AuthService.useProfile();

	const { children, ...divProps } = props;

	const logged = !!(!isLoading && data);

	return (
		<>
			<Cover
				$hidden={logged}
				$time={time}
				message={data ? "" : t("errors.http._default.title")}
				showLoading={isLoading}
			/>

			{logged && (
				<Main data-testid="main-layout" {...divProps}>
					<Nav />
					<Content data-testid="main-layout/content">
						{children}
					</Content>
				</Main>
			)}
		</>
	);
}
