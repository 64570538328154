import { Entry } from "~/common";

import { RepositoryFromHttp } from "./common";
import { EntryHttpClient as httpClient } from "../clients";

export * as EntryRepositories from "./entry";

const { routes } = Entry.ENTRY_HTTP_CONFIG;

/** Repository options of 'Entry' to use with 'react-query' */
export const entryRepository = {
	findById: params => ({
		queryFn: () => httpClient.findById(params),
		queryKey: routes.findById.path(params).split("/"),
	}),
} as const satisfies RepositoryFromHttp<Entry.EntryHttp>;
