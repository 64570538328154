import { USER_HTTP_CONFIG, UserHttp } from "~/common/user";

import { RepositoryFromHttp } from "./common";
import { UserHttpClient as httpClient } from "../clients";

const { routes } = USER_HTTP_CONFIG;

/** Repository options of 'user' HTTP client to use with 'react-query' */
export const userRepository = {
	findAndCount: query => ({
		queryFn: () => httpClient.findAndCount(query),
		queryKey: [...routes.findAndCount.path({}).split("/"), query],
	}),
	findById: params => ({
		queryFn: () => httpClient.findById(params),
		queryKey: routes.findById.path(params).split("/"),
	}),
} as const satisfies Partial<RepositoryFromHttp<UserHttp>>;
