import { PLAN_HTTP_CONFIG, PlanHttp } from "~/common/plan";

import { RepositoryFromHttp } from "./common";
import { PlanHttpClient as httpClient } from "../clients";

const { routes } = PLAN_HTTP_CONFIG;

/** Repository options of 'plan' HTTP client to use with 'react-query' */
export const planRepository = {
	findAndCount: query => ({
		queryFn: () => httpClient.findAndCount(query),
		queryKey: [...routes.findAndCount.path({}).split("/"), query],
	}),
	findById: params => ({
		queryFn: () => httpClient.findById(params),
		queryKey: routes.findById.path(params).split("/"),
	}),

	create: {
		mutationFn: ([body]) => httpClient.create(body),
		mutationKey: routes.create.path({}).split("/"),
	},
	delete: { mutationFn: ([params]) => httpClient.delete(params) },
} as const satisfies RepositoryFromHttp<PlanHttp>;
