import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { HTMLAttributes } from "react";

import { Layout } from "..";
import {
	MainNavItemLink,
	MainNavLink,
	NAV_ICON_CONFIG,
	NavItemCss,
	NavItemCssProps,
} from "./MainLayoutNavItem";
import { ROUTES } from "../../../services/routes";
import { AuthService } from "../../auth/AuthService";
import { PeopleAvatar } from "../../people/components";
import { Icon } from "../../ui-atoms/components/Icons/Icon";
import { theme } from "../styles/theme";

const Main = styled.div`
	background: ${theme.palette.blue.primary};
	display: flex;
`;
const NavItemContainer = styled("div")<NavItemCssProps>`
	${NavItemCss}
`;

const VersionInfo = styled.p`
	color: ${theme.palette.white.primary};
	font-size: 10px;
	text-align: center;
	white-space: break-spaces;
`;

/** Props for {@link MainLayoutNav} */
export type MainLayoutNavProps = HTMLAttributes<HTMLDivElement>;
/** The Nav(bar) for the main layout */
export function MainLayoutNav(props: MainLayoutNavProps) {
	const { pathname } = useRouter();
	const { user } = AuthService.useGetConnectedProfile();
	const { mutate } = AuthService.useLogout();

	return (
		<Main data-testid="main-layout/nav" {...props}>
			<MainNavItemLink href={ROUTES.home.url()}>
				<Icon name="iconWhite" size={32} />
			</MainNavItemLink>

			<MainNavLink
				active={ROUTES.customers.isActive(pathname)}
				data-testid="nav/customers"
				icon="userMultiple"
				url={ROUTES.customers.url()}
			/>
			<MainNavLink
				active={ROUTES.plans.isActive(pathname)}
				data-testid="nav/plans"
				icon="pieChart"
				url={ROUTES.plans.url()}
			/>

			<Layout.Spring />

			<VersionInfo>{__APP_VERSION__}</VersionInfo>

			<NavItemContainer data-testid="nav/user" mode="inactive">
				<PeopleAvatar name={user.name} size="big" />
			</NavItemContainer>
			<NavItemContainer
				data-testid="nav/logout"
				mode="hover-able"
				onClick={() => mutate()}
			>
				<Icon
					color={NAV_ICON_CONFIG.colors.inactive}
					name="logout"
					size={NAV_ICON_CONFIG.size}
				/>
			</NavItemContainer>
		</Main>
	);
}
