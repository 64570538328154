import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import { DetailedHTMLProps, HTMLAttributes } from "react";

import { Layout } from ".";
import { textBody } from "./styles/textStyles";
import { theme } from "./styles/theme";
import Logo from "../../assets/images/Logo.svg";
import { Spinner } from "../ui-atoms/components/Spinner";

/** @internal */
interface CoverStyleProps {
	/** Show the cover? (via opacity) */
	$hidden: boolean;
	/** Transition time for the cover to disappear */
	$time: number;
}
/** @internal */
const Cover = styled("div")<CoverStyleProps>`
	align-items: center;
	background: ${theme.palette.background.level3};
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 24px;
	height: 100%;
	inset: 0;
	justify-content: center;
	opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};

	/** We use opacity to fade out the cover gently, and visibility so that we can interact below the cover. */
	transition:
		opacity ${({ $time }) => $time}ms linear,
		visibility ${({ $time }) => $time}ms linear;
	visibility: ${({ $hidden }) => ($hidden ? "hidden" : "visible")};
	width: 100%;
	z-index: var(--z-index-cover);
`;

const Content = styled("div")`
	${textBody}
	/** So that the cover logo does not move when loading / message appear or disappear */
	min-height: 40px;
`;

const LinedStyled = styled(Layout.Lined)`
	gap: 8px;
`;

/** Props for {@link MainCover} */
export interface MainCoverProps
	extends Partial<CoverStyleProps>,
		Pick<
			DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
			"className"
		> {
	/** A message displayed below the cover logo (only when showLoading == false) */
	message?: string;
	/**
	 * Show the loading text (animation?)
	 *
	 * @default true
	 */
	showLoading?: boolean;
}
/**
 * A component with the logo at the center (for loading cover, ...)
 *
 * @param props For the component generation
 */
export function MainCover(props: MainCoverProps) {
	const { t } = useTranslation();
	const {
		$hidden: hidden = false,
		$time: time = 250,
		message,
		showLoading = true,
		...rootProps
	} = props;

	return (
		<Cover {...rootProps} $hidden={hidden} $time={time}>
			<Logo />

			<Content>
				{showLoading && (
					<LinedStyled>
						<Spinner size="sm" />
						<span>{t("common.state.loading")}</span>
					</LinedStyled>
				)}
				{!showLoading && message && <span>{message}</span>}
			</Content>
		</Cover>
	);
}
