import { Entry } from "~/common";

import { EntryHttpClients } from "../../../clients";
import { RepositoryFromHttp } from "../../common";

const { routes } = Entry.ENTRY_HTTP_CONFIG.children.recipes.children.salaries;
const httpClient = EntryHttpClients.SalaryHttpClient;

/** Repository options of 'entry/recipe/salary' HTTP client to use with 'react-query' */
export const SalaryRepository = {
	findAndCount: (params, query) => ({
		queryFn: () => httpClient.findAndCount(params, query),
		queryKey: [...routes.findAndCount.path(params).split("/"), query],
	}),
	findById: params => ({
		queryFn: () => httpClient.findById(params),
		queryKey: routes.findById.path(params).split("/"),
	}),

	create: { mutationFn: ([params, body]) => httpClient.create(params, body) },
	delete: { mutationFn: ([params]) => httpClient.delete(params) },
	update: { mutationFn: ([params, body]) => httpClient.update(params, body) },
} as const satisfies RepositoryFromHttp<Entry.SalaryHttp>;
