import { CUSTOMER_HTTP_CONFIG, CustomerHttp } from "~/common/customer";

import { RepositoryFromHttp } from "./common";
import { CustomerHttpClient as httpClient } from "../clients";

const { routes } = CUSTOMER_HTTP_CONFIG;

/** Repository options of 'customer' HTTP client to use with 'react-query' */
export const customerRepository = {
	findAndCount: query => ({
		queryFn: () => httpClient.findAndCount(query),
		queryKey: [...routes.findAndCount.path({}).split("/"), query],
	}),
	findById: params => ({
		queryFn: () => httpClient.findById(params),
		queryKey: routes.findById.path(params).split("/"),
	}),

	create: {
		mutationFn: ([body]) => httpClient.create(body),
		mutationKey: routes.create.path({}).split("/"),
	},
	update: {
		mutationFn: ([params, body]) => httpClient.update(params, body),
	},
} as const satisfies RepositoryFromHttp<CustomerHttp>;
